import React, { useEffect, useState } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import CredentialChangeMessage from "../components/auth/CredentialChangeMessage"
import SEO from "../components/seo.js"

const ResetPasswordConfirm = ({ location }) => {
  const [isMounted, setIsMounted] = useState(false)
  const email = !!location.state?.email

  useEffect(() => {
    if (!email) {
      navigate("/forgot-password")
      return
    }
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return <div></div>
  }

  return (
    <>
      <SEO title="Reset Password Confirm" path="/reset-password-confirm/" />
      <CredentialChangeMessage
        email={location.state?.email}
        changedCredential="Password"
      />
    </>
  )
}

ResetPasswordConfirm.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ResetPasswordConfirm
